import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import VueHighlightJS from 'vue3-highlightjs'

import App from './App.vue'
import pinia from './store'
import router from './router'
import useSettingsStore from './store/modules/settings'
// 自定义指令
import directive from '@/utils/directive'
// 加载 svg 图标
import 'virtual:svg-icons-register'
// 全局样式
import '@/assets/styles/globals.scss'

import 'highlight.js/styles/solarized-light.css'

// 加载 iconify 图标（element plus）
import { downloadAndInstall } from '@/iconify-ep'
if (useSettingsStore().app.iconifyOfflineUse) {
  downloadAndInstall()
}

const app = createApp(App)
app.use(ElementPlus)
app.use(pinia)
app.use(router)
app.use(VueHighlightJS)

directive(app)

app.mount('#app')
