import type { Route } from '@/global'

const Layout = () => import('@/layouts/index.vue')

const routes: Route.recordRaw = {
  path: '/dashboard',
  component: Layout,
  redirect: '/dashboard/prompts',
  name: 'DashboardRoute',
  meta: {
    title: 'Dashboard',
    icon: 'ep:data-line',
  },
  children: [
    {
      path: 'prompts',
      name: 'prompts',
      component: () => import('@/views/dashboard/prompt.vue'),
      meta: {
        title: 'Prompts 列表',
        icon: 'ep:chat-line-square',
      },
    }, {
      path: 'tasks',
      name: 'tasks',
      component: () => import('@/views/dashboard/index.vue'),
      meta: {
        title: '任务列表',
        icon: 'ep:picture-filled',
      },
    },
  ],
}

export default routes
