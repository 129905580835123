const __pages_import_0__ = () => import("/src/views/personal/setting.vue");
const __pages_import_1__ = () => import("/src/views/personal/edit.password.vue");
const __pages_import_2__ = () => import("/src/views/dashboard/prompt.vue");
const __pages_import_3__ = () => import("/src/views/dashboard/index.vue");
const __pages_import_4__ = () => import("/src/views/breadcrumb_example/list2.vue");
const __pages_import_5__ = () => import("/src/views/breadcrumb_example/list1.vue");
const __pages_import_6__ = () => import("/src/views/breadcrumb_example/detail2.vue");
const __pages_import_7__ = () => import("/src/views/breadcrumb_example/detail1.vue");
const __pages_import_8__ = () => import("/src/views/reload.vue");
const __pages_import_9__ = () => import("/src/views/login.vue");
const __pages_import_10__ = () => import("/src/views/[...all].vue");

const routes = [{"name":"personalSetting","path":"/personal/setting","component":__pages_import_0__,"props":true,"meta":{"title":"个人设置","cache":"personal-edit.password"}},{"name":"personalEditPassword","path":"/personal/edit.password","component":__pages_import_1__,"props":true,"meta":{"title":"修改密码"}},{"name":"dashboard","path":"/dashboard/prompt","component":__pages_import_2__,"props":true,"meta":{"title":"Prompt"}},{"name":"dashboard","path":"/dashboard","component":__pages_import_3__,"props":true,"meta":{"title":"控制台"}},{"name":"breadcrumb_example-list2","path":"/breadcrumb_example/list2","component":__pages_import_4__,"props":true,"meta":{"enabled":false}},{"name":"breadcrumb_example-list1","path":"/breadcrumb_example/list1","component":__pages_import_5__,"props":true,"meta":{"enabled":false}},{"name":"breadcrumb_example-detail2","path":"/breadcrumb_example/detail2","component":__pages_import_6__,"props":true,"meta":{"enabled":false}},{"name":"breadcrumb_example-detail1","path":"/breadcrumb_example/detail1","component":__pages_import_7__,"props":true,"meta":{"enabled":false}},{"name":"reload","path":"/reload","component":__pages_import_8__,"props":true},{"name":"login","path":"/login","component":__pages_import_9__,"props":true,"meta":{"title":"登录","constant":true,"layout":false}},{"name":"notFound","path":"/:all(.*)*","component":__pages_import_10__,"props":true,"meta":{"title":"找不到页面","constant":true,"layout":false}}];

export default routes;