import useRouteStore from './route'
import useMenuStore from './menu'
import api from '@/api'

const useUserStore = defineStore(
  // 唯一ID
  'user',
  {
    state: () => ({
      account: localStorage.account || '',
      token: localStorage.token || '',
      permissions: [] as string[],
    }),
    getters: {
      isLogin: (state) => {
        let retn = false
        if (state.token) {
          retn = true
        }
        return retn
      },
    },
    actions: {
      login(data: {
        account: string
        password: string
      }) {
        return new Promise<void>((resolve, reject) => {
          // 通过 mock 进行登录
          api.post('internal/user/login', data).then((res) => {
            localStorage.setItem('account', data.account)
            localStorage.setItem('token', res.data.token)
            this.account = data.account
            this.token = res.data.token
            resolve()
          }).catch((error) => {
            reject(error)
          })
        })
      },
      logout() {
        return new Promise<void>((resolve) => {
          const routeStore = useRouteStore()
          const menuStore = useMenuStore()
          localStorage.removeItem('account')
          localStorage.removeItem('token')
          this.account = ''
          this.token = ''
          routeStore.removeRoutes()
          menuStore.setActived(0)
          resolve()
        })
      },
      // 获取我的权限
      getPermissions() {
        return new Promise<string[]>((resolve) => {
          // 通过 mock 获取权限
          api.get('member/permission', {
            baseURL: '/mock/',
            params: {
              account: this.account,
            },
          }).then((res) => {
            this.permissions = res.data.permissions
            resolve(res.data.permissions)
          })
        })
      },
      editPassword(data: {
        password: string
        newpassword: string
      }) {
        return new Promise<void>((resolve) => {
          api.post('member/edit/password', {
            account: this.account,
            password: data.password,
            newpassword: data.newpassword,
          }, {
            baseURL: '/mock/',
          }).then(() => {
            resolve()
          })
        })
      },
    },
  },
)

export default useUserStore
